<template>
  <button
    @click.prevent="
      () => {
        !disabled ? press() : () => {};
      }
    "
    :disabled="disabled"
    :class="
      disabled
        ? 'cursor-not-allowed text-gray-50 bg-gray-300'
        : 'text-gray-50 bg-green-500 hover:bg-green-600'
    "
    class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none"
  >
    <slot v-if="!loading"></slot>
    <transition name="fade" mode="out-in">
      <spinner-component v-if="loading" size="20px" color="#ffffff" />
    </transition>
  </button>
</template>

<script>
import Spinner from '@/components/animations/SpinnerButton.vue';

export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    'spinner-component': Spinner,
  },
  methods: {
    press() {
      this.$emit('whenClick');
    },
  },
};
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 0s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
