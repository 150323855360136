<template>
  <div
    class="logo-component-flexbox flex justify-center items-center md:items-start items-center"
    :class="view === 'login' ? 'mt-12' : 'mt-12'"
  >
    <div
      v-if="view === 'login'"
      class="logo-component-container border rounded-md border-gray-300 py-12 px-6 flex flex-col items-center"
    >
      <span
        class="text-2xl text-gray-700 font-light mb-6 w-full login-form-width2 text-center"
      >
        Ingresa a tu portal
      </span>
      <div class="login-form-container">
        <div class="login-form">
          <transition name="fadeHeight" mode="out-in">
            <alert-box
              v-if="
                errorLogin != '' ||
                  (Array.isArray(errorLogin) && errorLogin.length != 0)
              "
              class="login-alert-box"
              type="danger"
              :title="''"
              :errors="errorLogin"
            />
          </transition>
          <div class="w-full flex flex-col justify-center items-start">
            <span class="text-sm text-gray-600"
              >Dirección de correo electrónico</span
            >
            <input
              v-model="form.email"
              name="email"
              type="email"
              autocomplete="email"
              required
              :class="
                errorEmailLogin
                  ? 'ring-red-500 border-red-500 border-3 bg-red-50'
                  : 'border'
              "
              class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="Correo electrónico"
            />
          </div>
          <div class="w-full flex flex-col justify-center items-start">
            <span class="flex flex-row justify-between w-full">
              <span class="text-sm text-gray-600">Contraseña</span>
            </span>
            <input
              v-model="form.password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="Contraseña"
              @keyup.enter="submit()"
            />
          </div>
          <div class="w-full flex flex-row justify-start items-center mb-4">
            <router-link
              class="text-sm cursor-pointer underline text-green-600 hover:text-green-800"
              to="/forgot"
              >¿Olvidaste tu contraseña?</router-link
            >
          </div>
          <div class="w-7/12 my-2">
            <button-component @whenClick="submit()" :loading="!canSubmitLogin">
              <strong>Entrar</strong>
            </button-component>
          </div>
        </div>
      </div>
      <!-- <div class="login-form-container mt-4">
        <div class="login-form">
          <span class="w-full text-center mb-2 mt-4">
            <p class="text-lg w-full text-center">
              ¿No tienes cuenta en nuestra plataforma?
            </p>
          </span>
          <span class="w-full text-center mb-2">
            <span class="font-bold">Si eres paciente, </span
            ><a
              class="text-md cursor-pointer underline text-green-600 hover:text-green-800"
              @click="
                () => {
                  view = 'register';
                }
              "
              >crea tu cuenta aquí</a
            >.
          </span>
        </div>
      </div>
      <span class="w-full text-center">
        <span class="font-bold"
          >Si quieres abrir una cuenta profesional en Futura, </span
        >escríbenos a contacto@futuraconsultas.cl o por whatsapp al +56 9 7568
        1564.
      </span> -->
    </div>
    <!-- <div class="logo-component-container-divider"></div> -->
    <div v-if="view === 'register'" class="logo-component-container mb-16">
      <h1 class="text-2xl text-gray-900 mb-2 login-form-width text-left">
        Regístrate
      </h1>
      <!-- <p class="text-md text-gray-600 mb-2 login-form-width text-justify">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Et sed earum
        suscipit quae necessitatibus at officia, laboriosam error illum veniam
        veritatis voluptatibus facilis magni ipsam pariatur, eligendi recusandae
        ad itaque.
      </p> -->
      <div
        class="w-full login-form-width grid grid-cols-1 md:gap-4 items-start"
      >
        <div class="w-full flex flex-col justify-center items-center">
          <transition name="fadeHeight" mode="out-in">
            <alert-box
              v-if="
                errorRegister != '' ||
                  (Array.isArray(errorRegister) && errorRegister.length != 0)
              "
              class="login-alert-box"
              type="danger"
              :title="''"
              :errors="errorRegister"
            />
          </transition>
          <div class="w-full flex flex-row justify-start items-center mb-1">
            <div class="flex flex-col justify-start items-start">
              <label
                class="w-full text-left block text-sm text-left font-medium text-gray-700"
              >
                Nombres y Apellidos
              </label>
              <label
                class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
              >
                Escribir tal como aparecen en carnet de identidad.
              </label>
            </div>
          </div>
          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-2">
            <input
              v-model="register_data.names"
              name="names"
              type="text"
              autocomplete="names"
              required
              class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="Nombres"
            />
            <input
              v-model="register_data.lastnames"
              name="lastnames"
              type="text"
              autocomplete="lastnames"
              required
              class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="Apellidos"
            />
          </div>
          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-2">
            <div class="col-span-2">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-x-2">
                <div class="flex flex-col justify-start items-start">
                  <label
                    class="w-full text-left block text-sm text-left font-medium text-gray-700"
                  >
                    Número teléfono
                  </label>
                  <label
                    class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
                  >
                    Código país al que está asociadla cuenta Whatsapp de tu
                    teléfono.
                  </label>
                </div>
                <div
                  class="hidden md:block flex flex-col justify-start items-start"
                >
                  <label
                    class="w-full text-left block text-sm text-left font-medium text-gray-700"
                  >
                    Rut
                  </label>
                  <label
                    class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
                  >
                    (sin puntos y con guión).
                  </label>
                </div>
              </div>
              <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-2">
                <div class="w-full grid grid-cols-3 gap-x-2">
                  <div class="mb-2 w-full relative flex items-center">
                    <button
                      @click="
                        () => {
                          countrySelector = !countrySelector;
                        }
                      "
                      v-click-outside="
                        () => {
                          countrySelector = false;
                        }
                      "
                      type="button"
                      class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      aria-haspopup="listbox"
                      aria-expanded="true"
                      aria-labelledby="listbox-label"
                    >
                      <span class="flex items-center">
                        <img
                          :src="country.flag"
                          alt=""
                          class="flex-shrink-0 h-6 w-6"
                        />
                        <span class="ml-3 block truncate">
                          ({{ country.dialCode }}) {{ country.name }}
                        </span>
                      </span>
                      <span
                        class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                      >
                        <!-- Heroicon name: solid/selector -->
                        <svg
                          class="h-5 w-5 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </button>

                    <transition
                      enter-active-class=""
                      enter-class=""
                      enter-to-class=""
                      leave-active-class="transition ease-in duration-100"
                      leave-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ul
                        v-if="countrySelector"
                        class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                        tabindex="-1"
                        role="listbox"
                        aria-labelledby="listbox-label"
                        aria-activedescendant="listbox-option-3"
                      >
                        <li
                          v-for="(countryElement, countryIndex) in countries"
                          :key="countryIndex"
                          class="cursor-default select-none relative py-2 pl-3 pr-9"
                          :class="
                            countryElement.isoCode === country.isoCode
                              ? 'bg-gray-200'
                              : 'text-gray-900'
                          "
                          id="listbox-option-0"
                          role="option"
                          @click="
                            () => {
                              country = countryElement;
                            }
                          "
                        >
                          <div class="flex items-center">
                            <img
                              :src="countryElement.flag"
                              alt=""
                              class="flex-shrink-0 h-6 w-6"
                            />
                            <span
                              class="ml-3 block truncate"
                              :class="
                                countryElement.isoCode === country.isoCode
                                  ? 'font-semibold'
                                  : 'font-normal'
                              "
                            >
                              ({{ countryElement.dialCode }})
                              {{ countryElement.name }}
                            </span>
                          </div>

                          <span
                            class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4"
                            :class="
                              countryElement.isoCode === country.isoCode
                                ? 'text-green-500'
                                : 'hidden'
                            "
                          >
                            <!-- Heroicon name: solid/check -->
                            <svg
                              class="h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </span>
                        </li>

                        <!-- More items... -->
                      </ul>
                    </transition>
                  </div>
                  <div class="col-span-2 mb-2 w-full flex rounded-md shadow-sm">
                    <!-- <span
                      class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm"
                    >
                      {{ country.dialCode }}
                    </span> -->
                    <input
                      v-model="register_data.phone"
                      name="rut"
                      type="text"
                      autocomplete="phone"
                      required
                      class="focus:ring-green-500 focus:border-green-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
                      placeholder="ej: 912345678"
                    />
                  </div>
                </div>
                <div>
                  <div
                    class="block md:hidden flex flex-col justify-start items-start"
                  >
                    <label
                      class="w-full text-left block text-sm text-left font-medium text-gray-700"
                    >
                      Rut
                    </label>
                    <label
                      class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
                    >
                      (sin puntos y con guión).
                    </label>
                  </div>
                  <input
                    v-model="register_data.rut"
                    name="rut"
                    type="text"
                    autocomplete="rut"
                    required
                    :class="
                      errorRut
                        ? 'ring-red-500 border-red-500 border-3 bg-red-50'
                        : 'border'
                    "
                    class="mb-2 appearance-none relative block w-full px-3 py-3 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    placeholder="ej: 11111111-1"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-2">
            <input
              v-model="register_data.email"
              name="email-register"
              type="email"
              autocomplete="email-register"
              required
              :class="
                errorEmailRegister
                  ? 'ring-red-500 border-red-500 border-3 bg-red-50'
                  : 'border'
              "
              class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="Correo electrónico"
            />
            <input
              v-model="register_data.email_confirmation"
              name="email-register"
              type="email"
              autocomplete="email-register"
              required
              :class="
                errorEmailRegister
                  ? 'ring-red-500 border-red-500 border-3 bg-red-50'
                  : 'border'
              "
              class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="Confirmar Correo electrónico"
            />
          </div>
          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-2">
            <div class="w-full flex flex-col justify-center items-center">
              <label
                class="w-full text-left block text-sm text-left font-medium text-gray-700"
              >
                Fecha de nacimiento
                <span class="font-xs font-light mr-2" v-if="age != null"
                  >/</span
                >
                <span class="font-bold" v-if="age != null">{{ age }}</span>
              </label>
              <div class="mb-2 w-full grid grid-cols-1 md:grid-cols-3 gap-2">
                <select
                  v-model="register_data.year"
                  class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                >
                  <option selected disabled :value="''">Año</option>
                  <option
                    v-for="(year, yearIndex) in years_list()"
                    :key="yearIndex"
                    >{{ year }}</option
                  >
                </select>
                <select
                  v-model="register_data.month"
                  class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                >
                  <option selected disabled :value="''">Mes</option>
                  <option
                    v-for="(month, monthIndex) in months"
                    :key="monthIndex"
                    :value="monthIndex + 1"
                    >{{ month }}</option
                  >
                </select>
                <select
                  v-model="register_data.day"
                  class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                >
                  <option selected disabled :value="''">Día</option>
                  <option
                    v-for="(day, dayIndex) in days_list"
                    :key="dayIndex"
                    >{{ day }}</option
                  >
                </select>
              </div>
            </div>
            <div class="w-full flex flex-col justify-center items-center">
              <label
                for="country"
                class="w-full text-left block text-left text-sm font-medium text-gray-700"
                >Previsión de salud</label
              >
              <select
                name="prevision"
                autocomplete="prevision"
                v-model="register_data.prevision"
                class="col-span-6 mb-2 block w-full py-2 px-3 disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
              >
                <option selected disabled :value="null">Seleccionar</option>
                <option
                  v-for="(prevision, previsionIndex) in previsions"
                  :key="previsionIndex"
                  >{{ prevision }}</option
                >
              </select>
            </div>
          </div>
          <label
            class="w-full text-left block text-sm text-left font-medium text-gray-700"
          >
            Dirección de factura
          </label>
          <!-- <label
            class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
            adipisci facilis, deleniti repellendus nisi voluptatibus.
          </label> -->
          <div class="w-full mb-2 grid gap-x-2 grid-cols-1 md:grid-cols-2">
            <select
              v-model="register_data.region"
              class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
            >
              <option selected disabled :value="''">Region</option>
              <option
                v-for="(region, regionIndex) in regiones"
                :key="regionIndex"
                >{{ region.region }}</option
              >
            </select>
            <select
              name="comuna"
              autocomplete="comuna"
              v-model="register_data.comuna"
              class="col-span-2 md:col-span-1 mt-2 md:mt-0 w-full disabled:text-gray-400 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
            >
              <option selected disabled :value="''">Comuna</option>
              <option
                v-for="(comuna, comunaIndex) in comunas"
                :key="comunaIndex"
                >{{ comuna }}</option
              >
            </select>
            <input
              v-model="register_data.address"
              name="rut"
              type="text"
              autocomplete="address"
              required
              class="col-span-2 mt-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="Dirección"
            />
          </div>
          <label
            class="w-full text-left block text-sm text-left font-medium text-gray-700"
          >
            Crea una contraseña para tu cuenta
          </label>
          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-2">
            <input
              v-model="register_data.password"
              name="password-register"
              type="password"
              autocomplete="current-password"
              required
              class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="Contraseña"
            />
            <input
              v-model="register_data.confirmpassword"
              name="confirm-password"
              type="password"
              autocomplete="confirm-password"
              required
              class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              placeholder="Confirma la contraseña"
              @keyup.enter="register_submit()"
            />
          </div>
        </div>
      </div>
      <div class="login-form-width">
        <div class="login-remember">
          <div class="w-full">
            <input-checkbox
              placeholder="Acepto"
              link="Términos y Condiciones"
              url="/terms"
              class="no-margin"
              @checkbox="
                value => {
                  register_data.terms = value;
                }
              "
            />
          </div>
        </div>
        <div class="flex w-full justify-center items-center my-2">
          <div class="w-7/12">
            <button-component
              @whenClick="register_submit()"
              :loading="!canSubmitRegister"
            >
              <strong>Crear cuenta</strong>
            </button-component>
          </div>
        </div>
        <div class="login-form-container mt-8">
          <div class="login-form">
            <span class="w-full text-center mb-2 mt-4">
              <p class="text-lg">
                ¿Ya tienes una cuenta en nuestra plataforma?
              </p>
            </span>
            <span class="w-full text-center mb-2">
              <a
                class="text-md cursor-pointer underline text-green-600 hover:text-green-800"
                @click="
                  () => {
                    view = 'login';
                  }
                "
                >ingresa con tu cuenta aquí</a
              >.
            </span>
          </div>
        </div>
        <span class="w-full text-center">
          <span class="font-bold"
            >Si quieres abrir una cuenta profesional en Futura, </span
          >escríbenos a contacto@futuracentro.cl o por whatsapp al +56 9 7568
          1564.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InputCheckbox from '@/components/form/InputCheckbox.vue';
import AlertBox from '@/components/form/AlertBox.vue';
import Button from '@/components/form/Button.vue';
import moment from 'moment';
import regiones from '@/utils/comunas-regiones.json';
import countries from '@/utils/country-codes.json';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'login',
  components: {
    'input-checkbox': InputCheckbox,
    'button-component': Button,
    'alert-box': AlertBox,
  },
  data() {
    return {
      view: 'login',
      regiones: regiones,
      countries: countries,
      countrySelector: false,
      country: {
        name: 'Chile',
        dialCode: '+56',
        isoCode: 'CL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cl.svg',
      },
      comunas: [],
      form: {
        email: '',
        password: '',
        remember: false,
      },
      register_data: {
        names: '',
        lastnames: '',
        rut: '',
        email: '',
        phone: '',
        email_confirmation: '',
        password: '',
        confirmpassword: '',
        terms: false,
        prevision: null,
        birthdate: null,
        day: '',
        month: '',
        year: '',
        note: '',
        region: '',
        comuna: '',
        address: '',
      },
      previsions: [
        'Fondo Nacional De Salud (Fonasa)',
        'Isalud Isapre De Codelco',
        'Isapre Banmédica',
        'Isapre Colmena',
        'Isapre Consalud',
        'Isapre Cruz Blanca',
        'Isapre Cruz Del Norte',
        'Isapre Fundación Banco Estado',
        'Isapre Nueva Másvida',
        'Isapre Vida Tres',
        'Particular',
        'Isapre Esencial',
      ],
      errorEmailLogin: false,
      errorEmailRegister: false,
      errorRut: false,
      show_pass: false,
      errorLogin: '',
      errorRegister: '',
      canSubmitLogin: true,
      canSubmitRegister: true,
    };
  },
  created() {
    if (this.$route.query.mode == 'register') {
      this.view = 'register';
    }
  },
  methods: {
    ...mapActions('authentication', ['login', 'register']),
    years_list() {
      const years = back => {
        const year = new Date().getFullYear();
        return Array.from(
          { length: back },
          (v, i) => year - back + i + 1
        ).reverse();
      };

      return years(100);
    },
    validateEmail: email => {
      const regex = /\S+@\S+\.\S+/;
      return regex.test(email);
    },
    validateRut: rut => {
      var Fn = {
        // Valida el rut con su cadena completa "XXXXXXXX-X"
        validaRut: function(rutCompleto) {
          if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
          var tmp = rutCompleto.split('-');
          var digv = tmp[1];
          var rut = tmp[0];
          if (digv == 'K') digv = 'k';
          return Fn.dv(rut) == digv;
        },
        dv: function(T) {
          var M = 0,
            S = 1;
          for (; T; T = Math.floor(T / 10))
            S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
          return S ? S - 1 : 'k';
        },
      };
      return Fn.validaRut(rut);
    },
    submit() {
      if (!this.canSubmitLogin) {
        return;
      }
      // Validation
      this.setErrorLogin();
      if (!this.validateEmail(this.form.email)) {
        this.setErrorLogin('Correo no válido!');
        return;
      }

      // Login Call
      this.canSubmitLogin = false;
      this.login({
        email: this.form.email,
        password: this.form.password,
      })
        .then(() => {
          this.$router.replace('home');
        })
        .catch(() => {
          this.setErrorLogin('Correo o Contraseña incorrectas!');
        })
        .finally(() => {
          this.canSubmitLogin = true;
        });
    },
    register_submit() {
      if (!this.canSubmitRegister) {
        return;
      }
      // Validation
      this.setErrorRegister();
      if (!this.validateEmail(this.register_data.email)) {
        this.setErrorRegister('Correo no válido!');
        return;
      }
      if (!this.validateRut(this.register_data.rut)) {
        this.setErrorRegister('Rut no válido!');
        return;
      }
      if (this.register_data.password !== this.register_data.confirmpassword) {
        this.setErrorRegister('Contraseñas no son iguales!');
        return;
      }
      if (this.register_data.email !== this.register_data.email_confirmation) {
        this.setErrorRegister(
          'Dirección de correo electrónico no son iguales!'
        );
        return;
      }
      if (!this.register_data.terms) {
        this.setErrorRegister('Debes Aceptar los Términos y Condiciones!');
        return;
      }
      if (!this.register_data.birthdate) {
        this.setErrorRegister('Debes ingresar tu fecha de nacimiento!');
        return;
      }

      if (!this.register_data.prevision) {
        this.setErrorRegister('Debes ingresar tu Previsión de salud!');
        return;
      }

      if (
        !this.register_data.comuna ||
        !this.register_data.region ||
        this.register_data.address == ''
      ) {
        this.setErrorRegister('Debes completar tu dirección de factura!');
        return;
      }

      // Login Call
      this.canSubmitRegister = false;
      this.register({
        names: this.register_data.names,
        lastnames: this.register_data.lastnames,
        phone: this.country.dialCode + this.register_data.phone,
        email_confirmation: this.register_data.email_confirmation,
        rut: this.register_data.rut,
        birthdate: this.register_data.birthdate,
        region: this.register_data.region,
        comuna: this.register_data.comuna,
        address: this.register_data.address,
        health: this.register_data.prevision,
        email: this.register_data.email,
        password: this.register_data.password,
        password_confirmation: this.register_data.confirmpassword,
      })
        .then(() => {
          this.$router.replace('home');
        })
        .catch(() => {
          this.setErrorRegister('Correo o Contraseña incorrectas!');
        })
        .finally(() => {
          this.canSubmitRegister = true;
        });
    },
    setErrorLogin(error) {
      if (!error) {
        this.errorLogin = [];
      } else {
        this.errorLogin.push(error);
      }
    },
    setErrorRegister(error) {
      if (!error) {
        this.errorRegister = [];
      } else {
        this.errorRegister.push(error);
      }
    },
  },
  watch: {
    'register_data.region'() {
      this.comunas = this.regiones.find(
        e => e.region === this.register_data.region
      )
        ? this.regiones.find(e => e.region === this.register_data.region)
            .comunas
        : [];
    },
    'register_data.day'() {
      if (
        this.register_data.day != '' &&
        this.register_data.month != '' &&
        this.register_data.year != ''
      ) {
        this.register_data.birthdate =
          this.register_data.day +
          '/' +
          this.register_data.month +
          '/' +
          this.register_data.year;
      } else {
        this.register_data.birthdate = '';
      }
    },
    'register_data.month'() {
      this.register_data.day = '';
    },
    'register_data.year'() {
      this.register_data.month = '';
    },
    'form.email'() {
      if (this.form.email != '') {
        this.errorEmailLogin = !this.validateEmail(this.form.email);
      } else {
        this.errorEmailLogin = false;
      }
    },
    'register_data.email'() {
      if (this.register_data.email != '') {
        this.errorEmailRegister = !this.validateEmail(this.register_data.email);
      } else {
        this.errorEmailRegister = false;
      }
    },
    'register_data.rut'() {
      if (this.register_data.rut != '') {
        this.errorRut = !this.validateRut(this.register_data.rut);
      } else {
        this.errorRut = false;
      }
    },
    'register_data.phone'() {
      if (this.country.isoCode === 'VE') {
        this.register_data.phone = this.register_data.phone.substring(0, 10);
      } else {
        this.register_data.phone = this.register_data.phone.substring(0, 9);
      }
    },
  },
  computed: {
    months() {
      moment.locale('es');
      if (this.register_data.year != '') {
        if (parseInt(this.register_data.year) == moment().year()) {
          console.log();
          let array = [];
          let selection = moment.monthsShort();
          for (let i = 0; i <= moment().month(); i++) {
            array.push(selection[i]);
          }
          return array;
        } else {
          return moment.monthsShort();
        }
      }
      return [];
    },
    days_list() {
      if (this.register_data.month != '' && this.register_data.year != '') {
        let dayInMonth = moment(
          this.register_data.year + '-' + this.register_data.month,
          'YYYY-MM'
        ).daysInMonth();
        let array = [];
        for (let i = 1; i <= dayInMonth; i++) {
          array.push(i);
        }
        return array;
      }
      return [];
    },
    age() {
      if (this.register_data.birthdate) {
        let date = moment(this.register_data.birthdate, 'DD/MM/YYYY');
        let years = moment().diff(date, 'years', false);
        let months = moment().diff(date, 'months', false);
        return (
          years +
          ' año' +
          (years != 1 ? 's' : '') +
          ' y ' +
          (months - 12 * years) +
          ' mes' +
          (months - 12 * years != 1 ? 'es' : '')
        );
      }
      return null;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
.logo-component-flexbox {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 900px) {
  .logo-component-flexbox {
    flex-direction: column;
    padding-bottom: 80px;
  }
}
@media only screen and (min-width: 901px) {
  .logo-component-flexbox {
    flex-direction: row;
  }
}

@media only screen and (max-width: 900px) {
  .logo-component-container-divider {
    width: 80%;
    border: 1px solid rgb(223 223 223 / 30%);
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 901px) {
  .logo-component-container-divider {
    height: 500px;
    border: 1px solid rgb(223 223 223 / 30%);
    margin-left: 25px;
    margin-right: 25px;
  }
}

.logo-component-container {
}
@media only screen and (max-width: 900px) {
  .logo-component-container:nth-child(2) {
    margin-top: 60px;
  }
}
@media only screen and (min-width: 901px) {
  .logo-component-container {
  }
}

.login-link-group {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.login-link {
  cursor: pointer;
  color: #111827;
  text-decoration: underline;
}
.login-link:not(:last-child) {
  margin-right: 1em;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 900px) {
  .login-form-container {
    min-width: 80vw;
  }
}
@media only screen and (min-width: 901px) {
  .login-form-container {
    min-width: 500px;
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 900px) {
  .login-form {
    max-width: 80vw;
  }
}
@media only screen and (min-width: 901px) {
  .login-form {
    max-width: 500px;
  }
}
.login-form-width {
  max-width: 100%;
}
@media only screen and (max-width: 900px) {
  .login-form-width2 {
    max-width: 80vw;
  }
}
@media only screen and (min-width: 901px) {
  .login-form-width2 {
    max-width: 500px;
  }
}
.logo-image {
  max-height: 80px;
  margin-bottom: 1em;
}
.login-remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.login-remember .login-checkbox {
  width: 50%;
}
.login-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.login-forgot {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  text-align: right;
}
@media only screen and (max-width: 900px) {
  .login-forgot {
    font-size: 12px;
  }
}
@media only screen and (min-width: 901px) {
  .login-forgot {
    font-size: 14px;
  }
}

.login-alert-box {
  margin-bottom: 1em;
}
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
